<template>
    <v-container fluid fill-height>
        <v-layout
          justify-center
          column
        >    
  <v-form v-model="valid" @submit.prevent="onSubmit" >
    <v-container>
        <v-row>
            <v-col>
                 <h1 class="white--text mb-6 mt-4 display-3 text-xs-center font-weight-bold" >ÄLVDALENS KOMMUN</h1>
       <h2 style="" class="white--text mb-6 mt-4 display-1 text-xs-center font-weight-bold" >INLOGGNING</h2>

            </v-col>
        </v-row>
    
      <v-row>
           <v-col
          cols="12"
          sm="6"
        >
         <v-text-field 
             label="Email"
            required
            filled
            :type="username"
            background-color="grey lighten-2"
            color="black"
            v-model="email"
></v-text-field>
        </v-col>
              <v-col
          cols="12"
          sm="6"
        >
          <v-text-field 
            label="Lösenord"
            v-model="password"
            :type="'password'"
              required
            filled
             background-color="grey lighten-2"
            color="black"
            class="mb-0 pb-0"
             ></v-text-field>
               <v-btn  color="white" class="mt-0 pt-0" text small @click="forgotPasswordDialog = true">Jag har glömt mitt lösenord</v-btn>

        </v-col>
      
      </v-row>
      <v-row  justify-center>
          <v-col 
          cols="6"
          sm="12">

            <v-flex xs12 sm12 align-end>
                
    <v-btn style="float:right;" color="#192514" type="submit" :loading="loading"
filled  large
     class="white--text text--lighten-3"
      :disabled="loading">Logga in</v-btn>
      <span v-if="loginError" class="white--text">Något gick fel</span>
    <router-link style="float:right;" class="mr-3 mt-2" to="register">Har du inget konto?</router-link><br>
    
        </v-flex>
             
          </v-col>
      </v-row>
     
    </v-container>
  </v-form>
        </v-layout>
          <v-dialog v-model="forgotPasswordDialog" width="500" class="">
        <div class="white text-md-left" style="padding: 50px 30px;">
         Ange din email nedan så skickar vi ut ett nytt lösenord till dig.
          <br><br>
             <v-container>
      <v-layout row wrap>

        <v-flex xs12 sm6>
          <v-text-field
            label="Email"
            required
            filled
            background-color="white"
            v-model="forgotEmailField"
          ></v-text-field>
           
           
        </v-flex>

        <v-btn @click="sendForgotPassword">Skicka</v-btn>

        <p v-if="resetInstruction">Om du var registrerad tidigare har ett email skickats med instruktioner för att återställa lösenordet. Stäng denna dialog och logga därefter in igen med ditt nya lösenord. </p>
      
      </v-layout></v-container>
        </div>
      </v-dialog>
    </v-container>
</template>

<script>
// @ is an alias to /src
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters(['user']),
    nextRoute () {
      return this.$route.query.redirect || '/'
    }
  },
    components: {
  },
   watch: {
    user (auth) {
      if(auth){
        console.log("apa")
        this.$router.replace("menu")
      }
    }
  },

    data: () => ({
      resetInstruction: false,
      forgotEmailField: '',
      forgotPasswordDialog: false,
      email: "",
      loginError: false,
      loading: false,
      username:'',
      password: "",
          valid: false,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 10 || 'Name must be less than 10 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      items: ['Göteborg', 'Uddevalla', 'Strömstad', 'Skövde']
    }),
     methods: {
       async sendForgotPassword() {

        await this.$auth.forgotEmail(this.forgotEmailField)
        this.resetInstruction = true;
        console.log("fds")

},
    async onSubmit () {
      console.log("login", this.email, this.password)
this.loginError = false;
      try{
        this.loading = true;
     await this.$auth.login(this.email, this.password);
    
      }
      catch(error) {
        console.log(error)
        this.loading = false;
        // Handle Errors here.
        var errorMessage = error.message;
        this.loginError = true;
        // [START_EXCLUDE]
        console.log(errorMessage);
    }
     
    }
  }
  }
</script>

<style>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
    background-color: blue;
  }
</style>
