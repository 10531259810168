<template>

    <v-container fluid fill-height>
        <v-layout
          justify-center
          column
        >    
  <v-form v-model="valid" @submit.prevent="onSubmit" >
    <v-container>
        <v-row>
            <v-col>
              <h2  class="mb-6 mt-6 display-1 text-xs-center font-weight-bold white--text" >INTRODUKTION</h2>
       <h1  class="mb-6 mt-4 display-3 text-xs-center font-weight-bold white--text" >ÄLVDALENS KOMMUN</h1>
       
            </v-col>
        </v-row>
 <staggered-fade :duration="40" tag="div" class="row">
        <!-- Each element requires a data-index attribute in order for the transition to work properly -->
        <v-col cols="12"  sm="6" lg="6"  v-for="(item, index) in categories"
          :data-index="index"
          :key="index">
  <v-btn
   color="#192514"

    class="white--text text--lighten-3 text-wrap"
    block
    elevation="6"
    @click="navigateToQuestion(item)"
    x-large
  >
    <v-icon left v-if="isFinished(item.id)" color="third">
      mdi-bookmark
    </v-icon>
    {{ item.name }}
  </v-btn>
        </v-col>
      </staggered-fade>

         
      
      
     
    </v-container>
  </v-form>
        </v-layout>
 
    </v-container>

</template>

<script>
// @ is an alias to /src

import { db } from "../plugins/FirebaseAuthPlugin";
import StaggeredFade from "../components/StaggeredFade";


export default {
  methods: {
    addCategory(){
this.categories.push({id:43243, name:"apa"});
    },
    persist() {
      localStorage.logo = this.logo;
      localStorage.background = this.menuBackground;
    },

    isFinished: function (id) {
      if(this.finishedCourses == null)
      {
        return false;
      }
      return this.finishedCourses.includes(id);
    },
    navigateToQuestion: function (item) {
      this.$router.push({ name: "questions", params: { id: item.id } });

    },
  },
  mounted() {},
  created() {
    if (localStorage.logo) {
      this.logo = localStorage.logo;
    }
    if (localStorage.background) {
      this.menuBackground = localStorage.background;
      this.$emit("updateBackground", this.menuBackground);
      
    } else {
      db.collection("settings")
        .doc("settings")
        .get()
        .then((doc) => {
          this.logo = doc.data().logo;
          this.menuBackground = doc.data().menuBackground;
          this.$emit("updateBackground", this.menuBackground);
          this.persist();
        });
    }

    db.collection("categories")
      .orderBy("sortorder")
      .get()
      .then((querySnapshot) => {

        let tmpArray = [];
        querySnapshot.forEach(function (doc) {
         
          var data = doc.data();
          var icon = data.icon != null ? data.icon : "none";
          tmpArray.push({ id: doc.id, name: data.name, icon: icon });
          
        });

        this.categories = tmpArray;
        
        // .reduce(function (
        //   result,
        //   value,
        //   index,
        //   array
        // ) {
        //   if (index % 2 === 0) result.push(array.slice(index, index + 2));
        //   return result;
        // },
        // []);

        //Get bookmarks if user finished any courses
        this.$auth.getUserRecord().then((data) => {
          this.finishedCourses = data.data().finishedCourses;

        if(this.finishedCourses){


          if (this.finishedCourses.length == tmpArray.length) {
            // this.diplomDialog = true;
            this.$auth.updateUserCompleted();
          }
            }
        });
      
      })
      .then(() => {
        this.isVisible = !this.isVisible;
      });
  },
  components: {
    StaggeredFade
    
  },
  data: () => ({
      nextNum: 10,
    menuBackground: "",
    logo: "",
    diplomDialog: false,
    finishedCourses: [],
    isVisible: false,
    categories: [],
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 10 || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    items: ["Göteborg", "Uddevalla", "Strömstad", "Skövde"],
  }),
};
</script>

<style>
.v-btn:not(.v-btn--round).v-size--x-large {
    height: 68px;
}
.v-btn__content {
  flex:1;
}
.home {
  font-family: "Trebuchet MS", sans-serif !important;
}

.swbuttonleft {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}
.swbuttonright {
  width: 380px;
  background-color: #7f7f7f !important;
  height: 76px;
  text-align: left;
  margin: 10px;
  border-radius: 50px !important;
}

.swbuttonleft .v-btn__content {
  justify-content: start;
}

.swbuttonright .v-btn__content {
  justify-content: end;
}

.swbuttonright .v-btn__content {
  margin-left: 25px;
}

.v-btn .v-icon--left {
  margin-right: 16px;
  margin-left: 23px;
}

.v-btn .v-icon--right {
  margin-right: 30px;
  margin-left: 23px;
}

body {
}

.sidebar {
  padding: 20px;
}

.item {
  width: 300px;
  height: 50px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
}

.v-btn__content {
  white-space: normal;
  width: inherit;
  word-break: break-word;
}

.staggered-transition {
    transition: all .5s ease;
    opacity: 1;
}
.staggered-enter, .staggered-leave {
    opacity: 0;
}
</style>


