<template>
  <v-container>
    <v-row no-gutters class="text-align:left;">
      <v-col md="12">
        <div class="float-left">
          <v-btn @click="$router.go(-1)">
            Tillbaka till meny
          </v-btn>
          <strong  class="white--text text--lighten-3"> {{ categoryName }}</strong>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col md="12">
        <div
          v-if="currentQuestion.type == 'video'"
          v-show="!isVisibleResult"
          class=""
          style="overflow: hidden;"
        >
          <div class="embed-container">
            <video
              ref="elearningvideo"
              class="elearningvideo"
              autoplay
              controls
              @ended="videoEnded"
              controlsList="nodownload nofullscreen nomute"
            >
              <source
                v-bind:src="currentQuestion.video"
                type="video/mp4"
                size="720"
              />
            </video>
        
          </div>
           
        </div>

        <div
          v-if="currentQuestion.type == 'file'"
          class="black--text headline"
          style="display: flex; flex-direction: column"
        >
          <div v-if="currentQuestion.file.indexOf('.jpg') > -1">
            <img v-bind:src="currentQuestion.file" width="100%" />
          </div>

          <div class="ma-7 " v-if="currentQuestion.file.indexOf('.pdf') > -1">
             <v-btn
              large
              class="mt-1 mb-2"
 
              :href="currentQuestion.file"
              target="_blank"
              >Ladda ner intyg</v-btn
            >

            <a :href="currentQuestion.file" target="_blank"></a>
          </div>
          <div
          style="background-color: rgba(255, 255, 255, 0.48)"
          class="pt-4 pb-4">
          <span v-html="currentQuestion.text"></span>
          </div>
        </div>

        <div
          v-if="currentQuestion.type == 'vimeo'"
          v-show="!isVisibleResult"
        >
          <div class="embed-container">
            <vueVimeoPlayer
              ref="player"
              @timeupdate="timeupdate"
              @ended="ended"
              :options="vimeo.options"
              :video-id="currentQuestion.vimeoId"
              @ready="onReady"
              class="elearningvideo"
            ></vueVimeoPlayer>
          </div>
        </div>

        <div
          
          v-show="!isVisibleResult"
          style="background-color: rgba(255, 255, 255, 0.48)"
          class="pt-4 pb-4"
        >
          <div
            v-if="
              currentQuestion.type == 'textfield' ||
              currentQuestion.type == 'text'
            "
            class="black--text headline"
            style="text-align: left; margin: 10px"
          >
            <span v-html="currentQuestion.text"></span>
          </div>
          <h2
            v-if="currentQuestion.type == 'question'"
            class="black--text mb-4 mt-4 headline"
          >
            {{ currentQuestion.question }}
          </h2>
          <h2
            v-if="currentQuestion.type == 'video'"
            class="black--text subheading text-xs-center"
          >
            {{ currentQuestion.text }}
          </h2>

          <v-flex xs12 v-if="currentQuestion.type == 'textfield'">
            <v-textarea
              style="margin-top: 10px; padding: 20px"
              name="input-7-1"
              label="Reflektera"
              solo
              v-model="currentQuestion.answer"
              hint="Försök beskriva så noga som möjligt"
            ></v-textarea>
          </v-flex>

          <div v-for="item in currentQuestion.answers" v-bind:key="item.id">
            <v-btn
              v-if="currentQuestion.type == 'question'"
              large
              class="no-capital mt-1 mb-2"
              v-bind:class="{
                correct: item.answered == true || (item.correct && done),
                incorrect: item.clicked == true && !item.correct,
                hidden: item.clicked != true &&done && item.answered == null && !item.correct,
              }"
              @click="answerQuestion(item)"
              >{{ item.name }}</v-btn
            >
          </div>

          <v-btn
            v-if="
              (currentQuestion.type == 'text' ||
                currentQuestion.type == 'textfield' ||
                currentQuestion.type == 'file') &&
              index > 1
            "
            @click="setPreviousQuestion()"
            style="float: left"
            filled
            color="secondary"
            class="black--text text--lighten-3"
            >Bakåt</v-btn
          >

          <v-btn
            v-if="
              currentQuestion.type != 'question'
            "
            @click="answerQuestion(currentQuestion)"
            style="float: right"
            class="white--text text--lighten-3"
            filled
            color="#192514"
            >Vidare</v-btn
          >
        </div>

        <v-flex
          xs10
          md5
          v-for="(item, index) in contacts"
          :index="index"
          :key="index"
          style="z-index: 1"
        >
          <v-flex class="white--text">
            <v-layout>
              <v-flex xs4>
                <v-img :src="item.avatar" height="125px" contain></v-img>
              </v-flex>
              <v-flex xs10>
                <v-card-title primary-title>
                  <div>
                    <div class="headline">{{ item.name }}</div>
                    <div>
                      <a :href="'mailto:' + item.phone" style="color: #e18538"
                        ><h3>{{ item.phone }}</h3></a
                      >
                    </div>
                  </div>
                </v-card-title>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>

        <v-flex xs12 sm12 v-show="isVisibleResult">
          <div style="background-color: #fff; margin-top: 60px">
            <!-- <div class="text-xs-left" style="color: #000; padding: 50px 30px">
              Tack för att du tittade, klicka på tillbaka för att komma till
              startmenyn igen.
            </div> -->
            <v-btn to="/menu" style="float: right">tillbaka till meny</v-btn>
          </div>
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { db } from "../plugins/FirebaseAuthPlugin";
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  created() {
    this.$auth.getUserRecord().then((data) => {
      var data2 = data.data();
      var time = data2["WatchTime" + this.$route.params.id];

      if (time != null) {
        this.currentWatchTime = time;
      }
    });

    db.collection("categories")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        let data = doc.data();

        if (data.data !== undefined) {
          this.$emit("updateBackground", data.background);

          this.categoryName = data.name;

          this.questions = data.data;
          this.contacts = data.kontakter;
          this.setNextQuestion();
          this.isVisible = !this.isVisible;
        }
      });
  },
  components: {
    vueVimeoPlayer,
  },
  data: () => ({
    vimeo: {
      options: {
        muted: false,
        autoplay: true,
      },
      playerReady: true,
    },

    categoryAnswers: [],
    contacts: [],
    categoryName: "",
    done: false,
    items: [0, 1, 2, 3, 4],
    valid: false,
    user: {
      numberOfCorrect: 0,
    },
    videoId: "",
    playerVars: {
      autoplay: 1,
    },
    isVisible: false,
    isVisibleResult: !1,
    index: -1,
    answerInProgress: false,
    currentQuestion: {},
    questions: [],
    currentWatchTime: 0,
    watchCounter: 0,
  }),

  computed: {
    numberOfQuestions: function () {
      return this.questions.filter(function (e) {
        return "question" == e.type;
      }).length;
    },
  },

  mounted() {
    //  this.setNextQuestion(), setTimeout(function () {
    //                     e.isVisible = !e.isVisible;
    //                 }, 500)
  },
  methods: {
    timeupdate(event) {
      if (parseInt(event.seconds) % 30 === 0) {
        this.watchCounter += 1;

        var tempTime = this.watchCounter;
        if (this.currentWatchTime != null) {
          tempTime += parseInt(this.currentWatchTime);
        }
        this.$auth.updateUserWatchTime(this.$route.params.id, tempTime);
      }
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
    ended() {
      this.videoEnded();
    },
    videoEnded: function () {
      this.answerQuestion(this.currentQuestion);
    },
    setNextQuestionWithAnimation: function () {
      let n = this;
      n.isVisible = !n.isVisible;
    },
    setPreviousQuestion: function () {
      this.index--,
        (this.currentQuestion = this.questions[this.index]),
        // this.currentQuestion.changeVideo && (this.videoId = this.currentQuestion.videoId),
        (this.answerInProgress = false);

      this.$nextTick(() => {
        //when playing videos after each other we need to do nextTick and .load to reload the html5 video src.
        if (this.currentQuestion.type == "video") {
          if (this.$refs.elearningvideo != null) {
            this.$refs.elearningvideo.load();
            this.$refs.elearningvideo.play();
          }
        }
      });
    },
    setNextQuestion: function () {
      this.index++,
        (this.currentQuestion = this.questions[this.index]),
        // this.currentQuestion.changeVideo && (this.videoId = this.currentQuestion.videoId),
        (this.answerInProgress = false);

      this.$nextTick(() => {
        //when playing videos after each other we need to do nextTick and .load to reload the html5 video src.
        if (this.currentQuestion.type == "video") {
          if (this.$refs.elearningvideo != null) {
            this.$refs.elearningvideo.load();
            this.$refs.elearningvideo.play();
          }
        }
      });
    },
    answerQuestion: function (e) {
      var n = this;
      e.clicked = true;

      if (!this.answerInProgress) {
        this.answerInProgress = true;

        this.done = true;
        if (typeof e.correct != undefined) {
          (e.answered = e.correct), e.correct && this.user.numberOfCorrect++;
        }

        if (this.currentQuestion.type == "textfield") {
          this.categoryAnswers.push({
            answer: e.answer,
            question: this.currentQuestion.text,
          });
        } else if (this.currentQuestion.question != "") {
          this.categoryAnswers.push({
            answer: e.name,
            correct: e.answered,
            question: this.currentQuestion.question,
          });
        }

        var waitTime = 0;
        if (this.currentQuestion.type == "question") {
          waitTime = 1200;
        }

        if (this.index == this.questions.length - 1) {
          this.$auth.updateUserFinishedCategory(
            this.$route.params.id,
            this.categoryAnswers
          );

          setTimeout(function () {
            (n.isVisible = !n.isVisible),
              setTimeout(function () {
                n.isVisibleResult = !n.isVisibleResult;
              }, waitTime);
          });
        } else {
          setTimeout(function () {
            (n.isVisible = !n.isVisible),
              (n.done = false),
              setTimeout(function () {
                (n.isVisible = !n.isVisible), n.setNextQuestion();
              }, 0);
          }, waitTime);
        }
      }
    },
  },
};
</script>

<style>
.no-capital{
      /* text-transform: uppercase; */
    text-transform: none;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swbutton {
  background-color: #4f8089 !important;
  height: 70px;
  text-align: left;
  margin: 10px;
}

body {
}

.sidebar {
  height: 100%;
  display: inline-block;
}

.item {
  height: 50px;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
  color: #000;
}

.correct {
  color:white !important;
  background-color: green !important;
}
.incorrect {
  background-color: red !important;
}

.hidden {
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.answerButton {
  float: left;
  width: 80%;
}

.disable-controls .plyr__controls {
  opacity: 0;
  & > * {
    pointer-events: none;
    cursor: auto;
  }
}

.ac-custom label {
  display: inline-block;
  position: relative;
  font-size: 2em;
  padding: 0 0 0 80px;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: color 0.3s;
}

audio::-webkit-media-controls-timeline {
  display: none;
}
audio::-webkit-media-controls {
  display: none;
}

@media screen and (max-width: 960px) {
  .elearningvideo {
    width: 100%;
    background: #d0d0d070;
  }
}

@media screen and (min-width: 960px) {
  .elearningvideo {
    width: 100%;
    background: #d0d0d070;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>


